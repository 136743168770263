import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleCasePipe } from '@angular/common'
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {
  displayedColumns = [
    'SN',
    'Title',
    'Description',
    'Author',
    'Date',
    'action']
  searchField: UntypedFormControl;
  toggle: boolean=false;
  showData:boolean=false;
  searchText:string='';
  selected_status:string='1';
  totalRecords: any=[];
  selectedType: string = 'blog';
  titleCasePipe = new TitleCasePipe();
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  dataSource: any = [];
  data: any = [];
  loader: boolean;
  form_action:string;
  update_data:any
  status: string = "1";
  filterTerm: string = "";
  blog_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  constructor(private api:ApiserviceService,
    private notify:NotificationService,
    private fnc:CommonfunctionService,
    private dialog:MatDialog,
    private menu:AdminMenusService) { 
      this.menu.setActive('blogList');
    }

  /**
   * action function to add, edit city ditails
   * @returns Void
   */
    /**
   * action function to add, edit city ditails
   * @returns Void
   */
    action(action?,ele_data?){
      // console.log(ele_data)
      if (action && action=='add') {
        this.form_action='add';
      }
      else if (action && action=='edit') {
        this.form_action='edit'
        this.update_data=ele_data;
      }
      this.toggle = !this.toggle
      if (this.toggle==false && action == 'update-list') {
        this.getBlogList();
      }
    }
   

  ngOnInit(): void {
    // this.getAllowResources();
    this.typeBlogsNews(this.selectedType);
   }

    /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
    getAllowResources() {
      let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
      this.api.getUmsData(body).subscribe({
        next :(res: any) => {  
          this.api.allowResources = res.data;
          this.blog_access = this.fnc.checkResourceAccess('blogs', false);
          this.getBlogList();
        
       }
      })
    }

    typeBlogsNews(selectedType: string): void {
      if (selectedType) { //if value selected
        this.selectedType = selectedType;  // Storeselected value
        // console.log("Selected type",this.selectedType);
        
        //this.getBlogList();  // Call API to fetch blog list with the selected type
        this.getAllowResources();
      }
    }


  /**
   * 
   * @param searchTerm 
   * @returns 
   */
  getBlogList(searchTerm?){
    if (!this.blog_access.GET) {
      this.notify.notify(`You are not Authorized to VIEW ${this.selectedType}`,'warn',4000);
      return;
    }
    this.loader=true;
    this.totalCount = 0; 
    let url = `blogs?status=${this.status}&is_count=true`;
    if (this.selectedType) {
      url += `&post_type=${this.selectedType}`;  // Append post_type to the query
    }

    this.api.getData(url).subscribe({
      next: (res:any)=>{
      this.loader=false;
      if (res.data && res.status==200) {
        let data = res.data;
        data && data.forEach((item, index )=> data[index]['filterkeys'] = data[index].post_title[this.api.language])
        this.dataSource=data;
        this.data=data;
        this.totalCount = res.totalRecord;  
      } else{
        this.dataSource = [];
        this.data = [];
        this.notify.notify(res.message,'warn',4000);
      }
    },error : err=>{
      this.dataSource = [];
      this.data = [];
      this.loader = false;
      this.notify.notify(err.error.message,'error',5000);
    }})
  }
  /**
   * bind data to table against keys 
   * @param element 
   * @param dispCol 
   * @returns 
   */
  returnVal(element, dispCol) {
    if (element) {
      switch (dispCol) {
        case "Title":
            return element.post_title[1];
        case "Author":
            return element.post_author;
        case "Description":
            return element.post_content[1];
        case "Date":
            return element.post_date;
      }
    }
  }
    
    public selected_stat;
    openConfirmation(value) {
      if (!this.blog_access.DELETE) {
        this.notify.notify(`You are not authorized to delete ${this.selectedType}`, "warn");
        return;
      }
      this.selected_stat = value;
      // let titleCasePipe = new TitleCasePipe();
      let _data = {
        parent_data: this,
        message: `Do you want to delete ${this.titleCasePipe.transform(this.selectedType)}?`,
      }
      const dialogRef = this.dialog.open(ConfirmDialogBox, {
        width: '350px',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
        this.confirmDialogYes(result);
      });
    }


    //Delete Blog API 
    confirmDialogYes(confirm) {
      if (confirm == 'YES') {
        let url = `blogs?id=${this.selected_stat.id}`;
       
        this.api.deleteData(url).subscribe({
          next :(data: any) => {
          if (data && data.status == 201) {
            this.notify.notify(`${this.titleCasePipe.transform(this.selectedType)} Deleted Successfully`, "success");
            this.getBlogList();
          }
        }, error :err => {
          this.notify.notify(`Unsuccessful ${this.selectedType} Deletion`, "error")
        }})
      }
    }

    getLabel(key) {
      return key ? key.replace(/_/g, " ") : "";
    }
  

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.dataSource = this.data.slice(this.offset, this.offset+this.limit);
    // this.getBlogList();
  } 
}

